* {
  box-sizing: border-box;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

:focus {
  outline: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
input,
p {
  margin: 0;
}

:root {
  --color-gray-1: #333;
  --color-gray-2: #4f4f4f;
  --color-gray-3: #808080;
  --color-gray-4: #c9c9c9;
  --color-aztec: #263238;
  --color-stainless-steel: #b5bec6;
  --color-doctor: #DCDCDC;
  --color-emerald-forest: #1d4a47;
  --color-pink: #d66987;
  --color-orange: #ffb21c;
  --color-white: #FEFAEE;
  --color-red: #de5072;

  // elements
  --border-color: var(--color-gray-4);
  --focus-background-color: var(--color-gray-1);
  --focus-border-color: var(--color-gray-1);
  --focus-shadow-color: #e6e6e6;

  // forms
  --form-background-color: #ffffff;
}

body {
  color: hsl(0, 0%, 14.9%);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  line-height: 24px;
}
