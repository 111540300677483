.gridContainer {
  background: url('../../assets/images/new bg and logos/newBg_50.webp');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  padding: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topLogoContainer {
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 100;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.partnerLogo {
  width: 7.5rem;
}

.bottomLogosContainer {
  display: none;
}

.bottomRightImage {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.bottomLeftImage {
  position: fixed;
  bottom: 20px;
  left: 30px;
}

.hipaaImage {
  width: 80px !important;
  height: auto !important;
}

.logoImage {
  width: auto !important;
  height: 70px !important;
}

@media screen and (max-width: 751px) {
  .hipaaImage {
    width: 40px !important;
  }

  .logoImage {
    height: 50px !important;
  }
}

@media screen and (min-width: 751px) and (max-width: 950px) {
  .gridContainer {
    grid-template-columns: 1fr;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 481px) and (max-width: 750px) {
  .topLogoContainer {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .jotForm {
    margin-top: 5rem;
  }
  .gridContainer {
    grid-template-columns: 1fr;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 480px) {
  .jotForm {
    margin-top: 5rem;
  }
}

@media screen and (max-height: 860px) {
  .gridContainer {
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .gridContainer {
    overflow-y: scroll;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .gridContainer {
    justify-content: flex-start;
  }

  .topContainer {
    margin-bottom: 1.5rem;
  }
}
