h1 {
    margin-bottom: 1.5rem;
    padding: 1rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
  }

  .buttonContainer {
    width: 100%;
  }

  h2 {
    padding: 1rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
  }

  .section {
    display: grid;
    row-gap: 1rem;
    align-items: flex-start;
    justify-items: center;
    margin-bottom: 0.5rem;
  }

  .fullSection {
    display: grid;
    row-gap: 1rem;
    margin: auto;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .disabled {
    pointer-events: none;
    opacity: .5;
  }

  form {
    width: 100%;
  }

  .bottomDescription {
    padding-top: 30px;
  }

  .threeSection {
    display: flex !important;
    flex-direction: column;
    width: 100%;
  }

  .buttonContainer{
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  .container {
    background-color: #FFFAEE;
    border-radius: 20px;
    border: 1px solid #B3B3B3;
    max-width: 880px;
    width: 100%;
    padding: 40px 70px;
    overflow: hidden;
    z-index: 9;
  }

  .dynamicLogo {
    height: 60px !important;
    width: auto !important;
    margin-top: 40px;
  }

  .disclaimerContainer {
    width: 55rem;
    margin-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .disclaimer {
    margin-top: 1.2rem;
    font-size: 0.8rem;
  }
  
  .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
   
    .heading {
      color: var(--color-gray-1);
      font-size: 30px;
      font-weight: 800;
      line-height: 1.3;
      text-align: center;
      margin-bottom: 0px !important;
    }

    .para {
      color: var(--color-gray-1);
      font-weight: 500;
      line-height: 1.3;
      text-align: center;
      margin-bottom: 3rem !important;
      font-size: 1.5rem;
    }

    .disclaimer {
      color: white;
    }

    .empty{
      width: 10rem;
    }

    .bottomContainer {
      margin-top: 70px;
      text-align: center;
      position: relative;
      
      .divider {
        position: absolute;
        background-color: #C3C1C1;
        height: 1px;
        width: 100vw;
        left: -90px;
      }

      p {
        font-size: 12px;
        line-height: 22px;
      }
    }

 
    @media screen and (min-width: 1357px) {
      .heading{
        font-size:45px;
        font-weight: bold;
      }
    }

    @media screen and (min-width: 1101px) and (max-width: 1356px) {

      .heading{
        font-size:45px;
        font-weight: bold;
      }
    }

    @media screen and (min-width: 751px) and (max-width: 1100px) {
      .container{
        width: 80%;
      }

      .disclaimerContainer {
        width: 80%;
        margin-top: 2.5rem;
      }

      .heading{
        font-size:38px;
        font-weight: bold;
      }

      .para {
        font-size: 20px;
      }
    }

    @media screen and (min-width: 481px) and (max-width: 750px) {
      .container{
        width: 90%;
        padding: 50px 30px;

      }

      .mainContainer {
        height: auto !important;
      }
    

      .disclaimerContainer {
        width: 90%;
        margin-top: 2.5rem;
      }

      .section {
        align-items: center;
        justify-items: center;
        align-content: space-evenly;
        align-items: center;
      }

      .fullSection {
        width: 100%;
        align-items: center;
        justify-items: center;
        align-content: space-evenly;
        align-items: center;
      }

      .threeSection {
        align-items: center;
        justify-content: space-evenly;
        justify-items: center;
        align-content: space-evenly;
        align-items: center;
      }

      .heading{
        font-size:30px;
        font-weight: bold;
      }

      .para {
        font-size: 14px;
        margin-bottom: 30px !important;

      }
    }
  
  
    @media screen and (max-width: 480px) {
      .dynamicLogo {
        height: 40px !important;
        margin-top: 20px;
      }
    
      .bottomDescription {
        padding-top: 20px;
      }

      .buttonContainer {
        button {
          font-size: 12px;
        }
    }

      .container{
        padding: 40px 20px;
        border-radius: 0.8rem;
      }

      .bottomContainer {
        margin-top: 30px;

        p {
          font-size: 11px;
          line-height: 20px;
        }
      }
      .mainContainer {
        height: auto !important;
      }


      .disclaimerContainer {
        width: 90%;
        margin-top: 2.5rem;
      }
  
      .section {
        display: grid;
        row-gap: 1rem;
        align-items: center;
        justify-items: center;
        align-content: space-evenly;
        align-items: center;
        margin-bottom: 0.5rem;
      }

      .fullSection {
        display: grid;
        row-gap: 1rem;
        margin: auto;
        width: 100%;
        align-items: center;
        justify-items: center;
        align-content: space-evenly;
        align-items: center;
        margin-bottom: 1.5rem;
      }

      .threeSection {
        display: grid;
        row-gap: 15px;
        align-items: center;
        justify-content: space-evenly;
        justify-items: center;
        align-content: space-evenly;
        align-items: center;
        margin-bottom: 0.5rem;
      }

      .heading{
        font-size:25px;
        font-weight: bold;
      }

      .para {
        font-size: 12px;
        margin-bottom: 30px !important;
        max-width: 216px;
      }
    }

    @media (min-width: 660px) {
      .section {
        grid-template-columns: 1fr 1fr;
        column-gap: 24px;
      }

      .fullSection {
        grid-template-columns: 1fr;
        column-gap: 24px;
        row-gap: 20px;
      }

      .threeSection {
        grid-template-columns: 2fr 1fr;
        column-gap: 24px;
        row-gap: 15px;
      }
    }

    @media screen and (max-width: 380px) {
      .container{
        width: 95%;
        padding: 1rem;
      }

      .heading{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: -4px !important;
    }

      .para {
        font-size: 12px;
        margin-bottom: 30px !important;
      }

      .disclaimerContainer {
        width: 95%;
        margin-top: 2.5rem;
        
      }

      .section {
        row-gap: 7px !important;

        button {
          font-size: 11px;
        }
      }
    }
  
   