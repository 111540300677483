main {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background-image: url('../../assets/images/new bg and logos/newBg_50.webp');
  background-size: cover;
  background-position-y: 100%;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: hsl(0, 0%, 100%);
}

.screeningHeader {
  font-weight: 600;
  text-align: center;
}

@media (min-width: 660px) {
  .container {
    border-radius: 8px;
    padding: 5rem;
  }
}
