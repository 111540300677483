.input {
  display: flex;
  flex-flow: column nowrap;
  row-gap:  0.37rem;
  width: 100%;
  label {
    padding: 0 0.1rem;
    font-size: 1.2rem;
    line-height: 2;
    color: var(--color-gray-1);
    font-weight: bold;
  }

  .inputFieldError {
    border-color:#FF1818 !important
  }

  input {
    // it has been used to fix date ui issue in ipad
    appearance: none;
    padding: 15px 10px;
    transition-property: border-color, box-shadow;
    transition-duration: 0.3s;
    border: 0.05rem solid #D9D9D9;
    border-radius: 0.3rem;
    background-color: var(--form-background-color);
    line-height: 0.65rem;
    margin-bottom: 0px;
    font-size: 12px;

    &:hover,
    &:focus {
      border-color: var(--focus-border-color);
      box-shadow: 0 0 0.05rem 0.1rem var(--focus-shadow-color);
    }
  }

  input::placeholder {
    font-size: 1rem !important;
    color: var(--color-gray-3) !important;
  }
}
  
  /* stylelint-disable-next-line no-descending-specificity */
  .invalid input {
    border-color: transparent;
  }
  